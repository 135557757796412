//
// Brand Dark theme
//

// Initialization of global variables, mixins and functions
@import '../../../init';

// Variables
$brand-bg: #1a1a27;
$brand-icon-color: #fff;
$brand-icon-color-hover: $primary;

$header-mobile-bg: #530b53;
$header-mobile-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
$header-mobile-icon-color: white;
$header-mobile-icon-color-hover: $primary;

.theme-btn {
    align-items: center;
    background: linear-gradient(144deg, #7a287a, #661166 50%, #b503b5) #7a287a !important;
    /* background: #7A287A !important; */
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.3) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;

    padding: 15px 20px;
    text-decoration: none;
    text-transform: none !important;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid none !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    min-width: 160px;
    min-height: 60px;
    background-size: 100% 100% !important;

    &,
    img {
        moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        background-size: 200% 200% !important;
        border: 1px solid none !important;
        box-shadow: rgba(151, 65, 252, 0.6) 0 15px 30px -10px !important;
    }
}

.gradient-btn {
    position: relative;
    align-items: center;
    background: linear-gradient(144deg, #7a287a, #661166 50%, #b503b5) #7a287a !important;
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.3) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;

    padding: 15px 30px;
    text-decoration: none;
    text-transform: none !important;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid none !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    background-size: 100% 100% !important;

    & {
        moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        background-size: 200% 200% !important;
        border: 1px solid none !important;
        box-shadow: rgba(151, 65, 252, 0.6) 0 15px 30px -10px !important;
    }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
    .brand {
        background-color: $brand-bg;
        box-shadow: none;
        padding-right: 10px !important;
        padding-left: 10px !important;

        // Button
        .btn {
            i {
                color: $brand-icon-color;
            }

            .svg-icon {
                @include svg-icon-color($brand-icon-color);
            }

            &.active,
            &:hover {
                .svg-icon {
                    @include svg-icon-color($brand-icon-color-hover);
                }

                i {
                    color: $brand-icon-color-hover;
                }
            }
        }
    }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
    .header-mobile {
        background-color: $header-mobile-bg;
        //box-shadow: $header-mobile-shadow;

        // Burger Icon
        .burger-icon {
            @include burger-icon-theme($header-mobile-icon-color, $header-mobile-icon-color-hover, $header-mobile-icon-color-hover);
        }

        // Button
        .btn {
            i {
                color: $header-mobile-icon-color;
            }

            .svg-icon {
                @include svg-icon-color($header-mobile-icon-color);
            }

            &.active,
            &:hover {
                .svg-icon {
                    @include svg-icon-color($header-mobile-icon-color-hover);
                }

                i {
                    color: $header-mobile-icon-color-hover;
                }
            }
        }
    }
}
