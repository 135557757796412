// Base colors
// Override primary color variants
$primary:       									#A84CA8;
$secondary:                                         #953395;
$contrast:                                          #FFBF00;
$gray-1:                                            hsl(0, 0%, 99%);
$gray-2:                                            #F4F4F4;
$gray-3:                                            #E6E6E6;;
$gray-4:                                            #C9C9C9;
$gray-5:                                            #878787;
$gray-6:                                            #585858;
$gray-7:                                            #353535;
$gray:                                              #4A464A;
$primary-hover:    									#187DE4; // Custom variable
$primary-light:    									#E1F0FF; // Custom variable
$primary-inverse:  									#FFFFFF; // Custom variable
$dark-green:                                        #0AA82D;
$light-green:                                       #D0FCD9;
$medium-green:                                      #0AA82D;

.primary {
    color: $primary;
}

.secondary {
    color: $secondary;
}

.btn {
    transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
}

.c-primary {
    color: $primary !important;
}

.bg-primary {
    background: $primary !important;
}

.bg-secondary {
    background: $secondary !important;
}

.btn-primary-c {
    &, &:hover {
        color: white;
        background: $primary !important;
        border: 1px solid $primary !important;
    }

    &:hover {
        opacity: .8;
    }
}

.btn-secondary-c {
    &, &:hover {
        color: $gray;
        background: $secondary !important;
        border: 1px solid $secondary !important;
    }

    &:hover {
        opacity: .8;
    }
}

// Size can also be negative; see how it's smaller than the element
.raise:hover,
.raise:focus {
  box-shadow: 0 0.8em 0.8em -0.2em transparentize($secondary, .8) !important;
  transform: translateY(-0.1em) !important;
}