.organization_upl {
    /* Home */
    .graphics-tables-section {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .tables-section, .graphics-section {
            width: 50%;
            max-width: 50%;
            min-width: 50%;
        }
    }
}