body {
    transition: $transition;
    font-size: 16px;
    background: white !important;
}

h1 { font-weight: 700 !important; color: $gray-7; }
h2 { font-weight: 300 !important; color: $gray-7; }
h3 { font-weight: 500 !important; color: $gray-7; }
h4 { font-weight: 500 !important; color: $gray-7; }
h5 { font-weight: 300 !important; color: $gray-7; }

.bg-violet-gradient {
    background: linear-gradient(144deg, #7A287A, #661166 50%, #b503b5);
}

.bg-contrast {
    background: $contrast;
}

.shadow-1 {
    filter: drop-shadow(7px 7px 16px rgba(0, 0, 0, 0.08));
}

// Apply filters to every autocomplete with the drop shadow
.MuiAutocomplete-root {
    filter: drop-shadow(7px 7px 16px rgba(0, 0, 0, 0.08));
}

.MuiChip-root {
    /* background: $primary !important; */
    background: linear-gradient(144deg,#7A287A, #661166 50%,#b503b5);

    .MuiChip-label {
        color: white !important;
        font-size: 1.1em !important;
        font-weight: 800 !important;
    }

    svg {
        fill: rgba(255,255,255,.5);
    }
}

.card {
    background: white !important;
    border: 1px solid rgba(224, 224, 224, .3) !important;
}

/* MUI Alerts */

.MuiAlert-filledError {
    background: #e01616 !important;
}

/* Effects hovering for buttons */
.pointer {
    cursor: pointer !important;
}

.brand-toggle {
    outline: none !important;
    border: 0 !important;
    transition: all 0.1s ease-in !important;
    -webkit-transition: all 0.1s ease-in !important;

    .icon-bg {
        transition: all 0.1s ease-in !important;
        -webkit-transition: all 0.1s ease-in !important;

        width: 30px;
        height: 30px;
        background: url(../images/common/arrow-left.png);
        background-size: contain;
    }

    &.active {
        .icon-bg {
            transform: rotate(180deg) !important;
        }
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em rgb(0 0 0 / 10%);
        transform: translateY(-0.1em);
    }
}

.button-hover-effect {
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
}

.button-hover-effect:hover {
    opacity: .6;
}

.card-body {
    padding-bottom: 0.75rem;
    border-radius: 5px;
}

.report-style-class-demo {
    width: 100% !important;
    height: 100% !important;
}

.report-style-class {
    height: 650px;

    iframe {
        border: 0 !important;
        border-radius: 10px;

        * {
            /* font-family: 'Noto Sans', sans-serif !important; */
            font-family: 'Roboto', sans-serif;
        }
    }
}

.modal {
    &.fade {
        background: rgba(0,0,0,0.75) !important;
    }
}

.MuiDivider-root {
    background: rgba(0,0,0,0.025);
    height: 2px;
    border-radius: 5px;
}

.MuiTableSortLabel-iconDirectionDesc, .MuiTableSortLabel-iconDirectionAsc {
    opacity: 1 !important;
}

.MuiAutocomplete-endAdornment {
    z-index: 5 !important;

    .MuiAutocomplete-clearIndicatorDirty {
        visibility: visible !important;
    }
}

.MuiAutocomplete-root {
    label {
        color: #BABBC8;
    }
    
    fieldset {
        border: 0;
        background: #F3F6F9;
        color: #3F4254;
    }

    input {
        color: #3F4254;
        z-index: 1 !important;
    }

    &.theme-white {
        label {
            color: #222;
        }
        
        fieldset {
            border: 0;
            /* background: #FFFFFF !important; */
            color: #222;
        }
    
        input {
            color: #222;
            z-index: 1 !important;
        }
    }

    .MuiAutocomplete-tag {
        z-index: 1 !important;
    }
}

.MuiDialog-container {
    background: rgba(0,0,0,0.4) !important;
}

.Mui-selected {
    span {
        font-weight: 600 !important;
    }
}

.btn.btn-primary.no-border, .btn.btn-primary.no-border-hover:hover {
    border-color: transparent !important;
}

.modal-client-details {
    .modal-dialog {
        min-width: 99% !important;
        margin: 0.5% !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }

    .modal-content {
        background: #E7EAF3;
    }
}

/* Progress bars */

.progress {
    position: relative;
    font-weight: 800 !important;

    .progress-text {
        position: absolute;
        left: 45%;
        top: 50%;
        color: #fff;
        text-shadow: 2px 2px 3px rgba(0,0,0,0.85);
    }

    &.red .progress-text {
        color: #666 !important;
        text-shadow: none !important;
    }

    &.green {
        & > div {
            background-color: #1BC5BD !important;
        }
    }

    &.yellow {
        & > div {
            background-color: #ffa800!important;
        }
    }

    &.red {
        & > div {
            background-color: #f64e60!important;
        }
    }
}

.c-green {
    color: #1BC5BD !important;
}

.c-yellow {
    color: #ffa800!important;
}

.c-red {
    color: #f64e60!important;
}

/* Carousel */
*[class^='Carousel-root'] {
    overflow: visible !important;
}
/* *[class^='Carousel-buttonWrapper'] button {
    opacity: .3 !important;

    &:hover {
        opacity: 1 !important;
    }
} */

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
    border-radius: 4px !important;
}

::-webkit-scrollbar {
	width: 12px;
    height: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
	background-color: #923092;
    border: 0px solid #ffffff;
    border-radius: 4px !important;
    /* border-radius: 3px; */
}

.stickyFirstColumn {
    thead > tr > th:nth-child(1),
    tbody > tr > td:nth-child(1) {
        position: sticky !important;
        left: 0 !important;
        z-index: 11 !important;
    }

    thead > tr > th:not(:nth-child(1)),
    tbody > tr > td:not(:nth-child(1)) {
        z-index: 10 !important;
    }

    tbody > tr > td:nth-child(1) {
        background: white !important;
    }
}

.modal-graph-test {
    min-width: 80%;
}

.modal-performance-view {
    min-width: 90%;
}

/* SweetAlert2 */

.swal2-container.swal2-backdrop-show {
    background: rgba(0,0,0,.9) !important;
    z-index: 9999 !important;
}

.swal2-title {
    font-size: 1.2em !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400 !important;
    color: #555 !important;
}

.withTooltip {
    transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;

    &:hover {
        opacity: .75 !important;
    }
}

.MuiTooltip-tooltip {
    background: rgba(255,255,255,.98);
    border: 1px solid #A84CA8;
    color: #222;
    font-weight: 400 !important;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    padding: 8px;

    font-size: 12px !important;

    .MuiTooltip-arrow::before {
        color: white !important;
        border: 1px solid rgba(168, 76, 168);
    }
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-bullet.menu-bullet-dot > span {
    background-color: white !important;
}