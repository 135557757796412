@media only screen and (max-width: 990px) {
    .graphics-tables-section {
        flex-direction: column !important;
    }

    .sales-distribution {
        width: 100% !important;
    }

    .buys-distribution {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .venn-diagram-container {
        .absolute-positioning {
            position: relative !important;
            width: auto !important;
            height: auto !important;
            position: relative !important;
            top: auto !important;
            right: auto !important;
        }
    }

    .full-width-mobile {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding: 0 !important;
    }
}

@media only screen and (max-width: 1100px) {
    .MuiTableContainer-root {
        overflow-x: auto;
        margin-right: auto;
        margin-left: auto;
          
        .title {
            margin-top: 20px;
            text-align: center;
        }
          
        tr {
            height: 40px !important;
        }
          
        th {
            /* max-width: 125px; */
            min-width: 150px;
            padding: 0 !important;
            /* overflow-x: auto; */
            white-space: nowrap;
            text-align: center !important;
        }
          
        td {
            /* height: 40px !important;
            padding: 5px !important; */
            text-align: center !important;
        }  
    }
}