// $theme-sidebar: #F7F5F5;
$theme-sidebar: #6E116F;
// $theme-sidebar-menu-active: white;
$theme-sidebar-menu-active: #A041A0;
// $color-menu-item-text: #2D3748;
$color-menu-item-text: #fff;
// $color-menu-item-icon: #7A287A;
$color-menu-item-icon: #fff;

.aside, .brand {
    background: $theme-sidebar !important;
}

.aside-minimize {
    .menu-item {
        width: 80% !important;
        margin: 0 auto !important;

        a {
            padding: 0 !important;

            svg {
                margin-left: 15px !important;
            }
        }
    }
}

.aside-menu {
    background: $theme-sidebar !important;

    /* .menu-bullet.menu-bullet-dot > span {
        background: $theme-sidebar-secondary-color !important;
    } */

    .menu-item {
        .menu-link {
            background: transparent !important;
        }

        .menu-arrow {
            color: $color-menu-item-icon !important;
        }

        .menu-bullet {
            span {
                color: $color-menu-item-text !important;
            }
        }

        &:hover, &:active {
            /* .menu-link {
                background: transparent !important;
                
                .menu-text {}
            } */
        }
    }

    .menu-link {
        transition: $transition;
        border-radius: 5px;
        width: 90% !important;
        margin: 3px auto !important;

        .menu-text {
            font-weight: 600 !important;
            transition: $transition;
            color: $color-menu-item-text !important;
        }
    }

    .menu-link.active {
        background: $theme-sidebar-menu-active !important;
        box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.5);
    }

    .menu-item svg g [fill] {
        transition: fill 0.3s ease !important;
        fill: $color-menu-item-icon !important;
        opacity: .2;
    }

    .menu-link.active svg g [fill] {
        opacity: 1;
    }
}

/* Material UI */

.MuiButton-containedPrimary {
    background: $primary;
    color: white !important;

    &:hover {
        background: $primary !important;
        color: white;
        opacity: .75 !important;
    }
}

.MuiButton-containedSecondary {
    background: #FABF03;
    color: #222 !important;

    &:hover {
        background: #FABF03;
        color: #222 !important;
        opacity: .75 !important;
    }
}

.btn-menu-dropdown {
    transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;

    padding: 8px;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    &:hover {
        background: transparent !important;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        transform: translateY(-2px);
    }
}